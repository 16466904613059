import React from "react";
import Typography from "@mui/material/Typography";

function ResultsFooter() {
  return (
    <>
      <Typography paragraph={true}>
        You should now contact the supplier of your choice to discuss your
        requirement in more detail. Before appointing a supplier you should
        ensure that they have satisfactory qualifications, accreditation,
        experience and insurance appropriate for the work.
      </Typography>
      <Typography paragraph={true}>
        We&apos;ve sent you an email with each supplier&apos;s estimate and
        contact details to refer to and forwarded your details to the suppliers,
        so some of them may contact you.
      </Typography>
      <Typography paragraph={true}>
        In about 3 weeks we&apos;d like to ask for any feedback you wish to give
        on suppliers you&apos;ve used, then after that your contact details will
        not be used again.
      </Typography>
    </>
  );
}

export default ResultsFooter;
